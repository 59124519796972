<template>
  <div>
    <!-- Page Header -->
    <section class="bg-dark pt-5 pb-4">
      <div class="container py-5">
        <h1 class="text-light pt-1 pt-md-3 mb-4">
          Confirm Job Closure
        </h1>
      </div>
    </section>
    <!--- / Page Header -->

    <!-- Job Details -->
    <section class="position-relative bg-white rounded-xxl-4 zindex-5" style="margin-top: -30px;">
      <div class="container pt-4 pb-5 mb-md-4">

        <breadcrumb v-if="job" :crumbs="crumbs" class="pb-4 my-2"></breadcrumb>

        <!-- Is Loading -->
        <div v-if="isLoading" class="text-center">
          <spinner></spinner>
        </div>
        <!-- / Is Loading -->

        <!-- Loaded -->
        <div v-if="!isLoading && job" class="row">
          <div class="col-lg-7 position-relative pe-lg-5 mb-5 mb-lg-0" style="z-index: 1025;">
            <!--            <close-job-form :job="job"></close-job-form>-->
            <h2 class="h3 mb-4">Please Confirm Total Fee</h2>

            <div class="card card-body">
              <h3 class="h6 mb-1">Total Fee:</h3>
              {{ formatCostInPence(job.job_closure.value) }}
            </div>

            <div class="mt-4">
              <confirm-job-closure-form :job="job"></confirm-job-closure-form>
            </div>
          </div>
        </div>
        <!-- / Loaded -->

      </div>
    </section>
    <!-- / Job Details -->

  </div>
</template>

<script>
import Spinner from "../../components/Spinner";
import jobs from "../../api/jobs";
import Forms from "../../mixins/Forms";
import Display from "../../mixins/Display";
import Breadcrumb from "../../components/Breadcrumb";
import Jobs from "../../mixins/Jobs";
import ConfirmJobClosureForm from "./ConfirmJobClosure/ConfirmJobClosureForm";
import {mapGetters} from "vuex";

export default {
  name: "ConfirmJobClosure",
  components: {Spinner, Breadcrumb, ConfirmJobClosureForm},
  mixins: [Forms, Jobs, Display],
  data() {
    return {
      isLoading: false,
      job: null
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    crumbs() {
      return [
        {
          href: "/",
          label: "Home",
        },
        {
          href: "/jobs",
          label: "Jobs",
        },
        {
          href: "/jobs/" + (this.job ? this.job.id : null),
          label: this.job ? this.job.title : '-'
        },
        {
          href: '#',
          label: 'Confirm Job Closure',
          active: true
        }
      ]
    }
  },
  watch: {},
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      let vm = this;
      vm.job = null;
      vm.isLoading = true;
      vm.serverErrors = [];
      jobs.find(this.$route.params.id).then(r => {
        vm.isLoading = false;
        vm.job = r.data;
      }).catch(e => {
        console.log(e);
        vm.setAndNotifyErrors(e, 'Error loading job');
      });
    }
  }
}
</script>

<style scoped>

</style>