<template>
  <div>
    <!-- Value -->
    <div class="mb-4">
      <label class="form-label fw-bold" for="value">Please enter the total you have billed for this job (£)</label>
      <input :disabled="isSaving" v-model="closure.value"
             class="form-control" type="number" step="0.01" id="value">
      <div class="form-text">Excluding VAT</div>
    </div>
    <!-- / Value -->

    <div class="mt-4">
      <button class="btn btn-primary"
              :class="{'opacity-50': (isSaving||!canSave)}"
              @click.prevent="closeJob"
              :disabled="(isSaving||!canSave)"
              type="submit">
        <spinner v-if="isSaving" class="spinner-border-sm me-2"></spinner>
        Close Job
      </button>
      <a class="ms-3" href="mailto:hello@syndesi.network">Get help with a job that is partially completed or cancelled</a>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Spinner from "../../../components/Spinner";
import axios from 'axios';
import {useToast} from 'vue-toastification';
import Forms from "../../../mixins/Forms";

const toast = useToast();

export default {
  name: "CloseJobForm",
  props: ['job'],
  mixins: [Forms],
  components: {Spinner},
  data() {
    return {
      closure: {
        value: null
      },
      isSaving: false,
      serverErrors: []
    }
  },
  methods: {
    getParams() {
      let params = {
        value: this.closure.value * 100
      };

      return params;
    },

    closeJob() {
      if (!this.canSave) {
        return false;
      }

      let vm = this;
      vm.isSaving = true;

      axios.post(window.API_BASE + '/jobs/' + this.job.id + '/close', this.getParams()).then(r => {
        vm.isSaving = false;
        toast.success('Job closed successfully');
        vm.$router.push('/jobs/' + this.job.id);
      }).catch(e => {
        vm.isSaving = false;
        vm.setAndNotifyErrors(e, 'Error closing job');
      });
    }
  },
  created() {
    if (this.acceptedJobQuote) {
      this.closure.value = this.acceptedJobQuote.total_fee / 100;
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    acceptedJobQuote() {
      if (this.job && this.job.job_quotes && this.job.accepted_job_quote_id) {
        return _.find(this.job.job_quotes, {id: this.job.accepted_job_quote_id});
      }
      return null;
    },

    canSave() {
      return (this.closure.value && this.closure.value > 0);
    }
  }
}
</script>

<style scoped>

</style>