<template>
  <div>
    <!--    <div class="mt-4">-->
    <div class="">
      <button class="btn btn-primary"
              :class="{'opacity-50': (isSaving||!canSave)}"
              @click.prevent="confirmJobClosure"
              :disabled="(isSaving||!canSave)"
              type="submit">
        <spinner v-if="isSaving" class="spinner-border-sm me-2"></spinner>
        Confirm & Close Job
      </button>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Spinner from "../../../components/Spinner";
import axios from 'axios';
import {useToast} from 'vue-toastification';
import Forms from "../../../mixins/Forms";

const toast = useToast();

export default {
  name: "ConfirmJobClosureForm",
  props: ['job'],
  mixins: [Forms],
  components: {Spinner},
  data() {
    return {
      isSaving: false,
      serverErrors: []
    }
  },
  methods: {
    getParams() {
      let params = {};
      return params;
    },

    confirmJobClosure() {
      if (!this.canSave) {
        return false;
      }

      let vm = this;
      vm.isSaving = true;

      axios.post(window.API_BASE + '/jobs/' + this.job.id + '/confirm-job-closure', this.getParams()).then(r => {
        vm.isSaving = false;
        toast.success('Job closure confirmed successfully');
        vm.$router.push('/jobs/' + this.job.id);
      }).catch(e => {
        vm.isSaving = false;
        vm.setAndNotifyErrors(e, 'Error confirming job closure');
      });
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    canSave() {
      return true;
    }
  }
}
</script>

<style scoped>

</style>